<template>
	<div class="content">
		<list-header :loading="loading" 
		:buttonFilter="buttonsFilter"/>	
		<div class="content--loading" v-if="loading">
			<filling-circle color="#057DCD" :size="42" />
		</div>
		<div class="content__list"  v-else-if="error">
			<span>Nothing to Display</span>
		</div>
		<div v-else class="details">
			<div class="details__sections">
				<div class="details__main-section">

					<div class="details__figure-section">
						<div class="details__heading">{{ detailsData.name || detailsData.reference }}</div>
						<div class="details__figure-container">
							<div class="details__figure" :style="{'background-image':`url(${this.logoUrl})`}">
								<img v-if="!logoUrl" class="details__logo" :src="require(`@/assets/images/Sections/${this.detailIcon}_dark.svg`)" alt="">
							</div>
						</div>
					</div>

					<div class="detail__info-section">
						<div class="details__heading">details</div>		
						<div class="details__info">
							<div class="details__info-table">
								<template v-for="(value,name) in detailsData" :key="name" class="details__item">
									<div class="detail__name">{{ detailsDisplayName(name) }}:</div>
									<div class="detail__value" >
										<span :class="{'details--highlight':highlightDetail(name),'details--highlight-active':highlightDetailActive(name,value)}">
											{{ detailsDisplayValue(name,value) }}  
											<span v-if="detailsFilters[name].percentage">
												( {{ displayPercentage(name) }}% )
											</span>
										</span>
									</div>
								</template>
							</div>
						</div>
					</div>		
				</div>
				<div class="details__list" v-if="detailsListFilter">

					<div class="details__heading">{{ detailsListFilter.title }}</div>
					<div class="details__container">
						<div class="details--scroll-x">
							<div class="details__list-table">
							<div class="details__list-header">
								<div class="details__list-header-cell" v-for="(value,name) in detailsListFilter.header" :key="name">{{ value }}</div>
							</div>
						
							<div v-if="detailsList ? detailsList.length < 1 : false" class="details__list-empty">"The list is empty" </div>
							<template v-else>
								<div class="details__list-item" :class="{'details__list--contains-items':item.contains_items}" v-for="(item,index) in detailsList" :key="index"  @click="gotoItemDetail(item)">
									<div class="details__list-item-cell"  v-for="(value,name) in detailsListFilter.header" :key="name">
										<!-- refactor Later -->
										
										<div style="display:inline-block" v-if="typeof item[name] === 'boolean'"
										@click="updatePendingItemFalse(item.id,index)"
										class="details--highlight"
										:class="{'details--highlight-active':!item[name]}">
											<span v-if="statusChanging[`${index}`]">wait...</span>
											<span v-else>{{ item[name] ? 'pending' : 'ready'  }}</span>
										</div>
										<span v-else>{{ item[name] }} </span>
									</div>
									<button v-show="!deleteList[`_${item.id}`] && deleteType && canManageOrders" @click="deleteItem(item.id)" class="details__list-delete">x</button>
									<div v-show="deleteList[`_${item.id}`]" class="details__delete-overlay"><span>deleting...</span></div>
								</div>
							</template>
						</div>
						</div>
					</div>
				</div>
				<history v-if="historyFilter" :id="id" />
			</div>
		</div>
		<div v-if="!error"><router-view v-if="!loading"/></div>
	</div>
</template>

<script>

import ListHeader from '@/components/ListHeader.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import History from './History.vue'
export default {
	components: { ListHeader,FillingCircle,History},
	name: 'Details',
	data(){
		return {
			loading:true,
			statusChanging:{},
			pollingTimer:null,
			error:false,
			deleteList:{},//move to store because of posibility of switching while loading
		}
	},
	methods:{
		inverseBoolean(value){
			return !value
		},

		updatePendingItemFalse(itemId,index){
			if(this.canRegulateOrders){
				this.statusChanging[`${index}`] = true
				this.$store.dispatch({type:'orders/updatePendingItemFalse', id:this.id, itemId})
				.then(()=>{
					this.statusChanging[`${index}`] = false

					this.$notify({
						title: "Update successful!",
						text:`Successfully deactivated pending order!`,
						type:"success"
					})
				})
				.catch((error)=>{
					this.statusChanging[`${index}`] = false
					console.log(error)
					this.$notify({
						title: "Failed Update Pending State",
						text: error,
						type:"error"
					})
					
				})
			}
		},
		
		setFilteredDetailsData(detailsData){
			let filters = this.detailsFilters
			let filteredDetailsData = {}
			//refactor time complexity of m*n
			for(const detail in detailsData){
				for(const filter in filters){
					if (filter === detail) filteredDetailsData[detail]=detailsData[detail];	 
				}
			}
			return filteredDetailsData
		},

		setBranchId(id){
			this.$store.commit({type:'setBranchId',branchId:id})
		},

		detailsDisplayName(name){
			return this.detailsFilters[name].substitute || this.detailsFilters[name]
		},

		detailsDisplayValue(name,value){	
			let options = this.detailsFilters[name]
			if (options.booleanValues){
				return value ? options.booleanValues[0] : options.booleanValues[1]
			}
			else return value
		},

		displayPercentage(name){
			let loadedState = this.$store.state[this.state.name]
			let loadedData = loadedState[this.state.data]
			let value = loadedData[ this.detailsFilters[name].percentage ]

			let percentage = 0

			if( value === 1 ) percentage = 5
			else if( value === 2 ) percentage = 10

			return percentage
		},

		highlightDetail(name){
			return this.detailsFilters[name].highlight
		},
		highlightDetailActive(name,value){
			return this.detailsFilters[name].highlight && !value
		},
	
		deleteItem(itemId){
			this.deleteList[`_${itemId}`] = true
			this.$store.dispatch({type:this.deleteType,itemId:itemId,id:this.id})
			.then(response=>{
				delete this.deleteList[`_${itemId}`]
				console.log(response)
			}).catch(error=>{
				this.$notify({
                    title: "Failed to Delete Item",
                    text: error,
                    type:"error"
                })
				console.log(error)
			})
		},

		loadData(){
			this.$store.dispatch({
				type:this.loadType,
				id:this.id
			}).then((response)=>{
				if(!this.getBranchId) {
					if ( this.$route.matched.some(route => route.path.includes('/order')) ) this.setBranchId(response.branch_id)
					else this.setBranchId(response.branch)
				}
				this.loading = false
				if (this.isPolling) this.pollingTimer = setTimeout(this.loadData,15000)
			}).catch((error)=>{
				this.loading = false
				this.error = true
				console.log(error)
			})
		},

		gotoItemDetail(item){
			if(item.contains_items) this.$router.push({name:'credits-item-details',params:{id:item.id}})
		}	

	},
	computed:{
		isVendor(){
			return this.$store.state.user.user_type === 'Vendor'
		},

		canManageOrders(){
			return (this.isVendor) ? true : this.$store.state.user.user_role.can_manage_orders
		},

		canRegulateOrders(){
			return (this.isVendor) ? true : this.$store.state.user.user_role.can_regulate_orders
		},
	
		buttonsFilter(){
			let titleButtons = this.$route.meta['TITLE_BAR_DATA'].buttons
			let selectedButton = ""
			for (const button of titleButtons){
				let buttonToggle = button.toggle || null
				let buttonValue = this.detailsData[buttonToggle]
				if (button.not){ buttonValue = this.inverseBoolean(buttonValue)}
				if (buttonToggle && buttonValue ) selectedButton = button.name
			}
			return selectedButton
		},
		getBranchId(){
			return this.$store.state.branchId
		},
		detailIcon(){
			return this.$route.meta['TITLE_BAR_DATA'].name.singular
		},
		id(){
			return this.$route.params.id
		},
		loadType(){
			return this.$route.meta['REQUESTS'].load
		},
		deleteType(){
			return this.$route.meta['REQUESTS'].delete
		},
		detailsFilters(){
			return this.$route.meta['RESPONSE_DATA_FILTER'].details
		},
		detailsListFilter(){
			return this.$route.meta['RESPONSE_DATA_FILTER'].list|| null
		},
		historyFilter(){
			let history = this.$route.meta['RESPONSE_DATA_FILTER'].history
			let isVendor  = this.$store.state.user.user_type === 'Vendor'
			
			let showHistory = (history & !isVendor) ? this.$store.state.user.user_role[history.userRole] : false

			let historyUserRole = isVendor ? true : showHistory

			return ( this.$route.meta['REQUESTS'].loadHistory && historyUserRole ) || null
		},
		detailsLitHeaderLenght(){
			let obj = this.detailsListFilter.header
			let size = 0
			for (let key in obj) {
				if (key) size++;
			}
			return size;
		},

		isPolling(){
			return this.$route.meta['REQUESTS'].isPolling
		},
		state(){
			return this.$route.meta['OPTIONS'].state
		},
		detailsData(){
			let loadedState = this.$store.state[this.state.name]
			let loadedData = loadedState[this.state.data]
			return this.setFilteredDetailsData(loadedData)
		},

		detailsList(){
			let loadedData
			let loadedState

			loadedState = this.$store.state[this.state.name]
			if(loadedState) loadedData = loadedState[this.state.data]
 
			return loadedData ? loadedData[this.detailsListFilter.type] : null
		},

		logoUrl(){
			let image
			let loadedState
			
			loadedState = this.$store.state[this.state.name]
			if(loadedState.data) image = loadedState[this.state.data].image
			return image ? `https://api.vendoorr.com${image}` : null
		}
	},

	beforeUnmount () {
		clearTimeout(this.pollingTimer)
	},

	mounted(){
		this.loadData()
		
	}

}

</script>
