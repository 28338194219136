<template>
  <div class="details__history">
    <div class="details__heading">History</div>
    <div class="details__container">
      <div class="details__history-item" v-for="(historyItem,index) in historyItems" :key="index">
        <div class="details__history-info">
          <div class="details__history-value"><span class="details__history-name">Consigned Product:</span>{{ historyItem.consigned_product }}</div>
          <div class="details__history-value"><span class="details__history-name"> Type:</span>{{ historyItem.action_type }}</div>
          <div class="details__history-value"><span class="details__history-name">Quantity:</span>{{ historyItem.quantity }}</div>
          <div class="details__history-value"><span class="details__history-name">Attendant:</span>{{ historyItem.attendant }}</div>
          <div class="details__history-value"><span class="details__history-name">Total Time Remaining:</span>{{ historyItem.total_remaining }}</div>
          <div class="details__history-value"><span class="details__history-name">Auto Track Item:</span>{{ historyItem.auto_tracked }}</div>
          <div class="details__history-date">{{ historyItem.modified }}</div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  props:{
    id:{
      type:String,
    }
  },
  computed:{
    loadHistory(){
      return this.$route.meta['REQUESTS'].loadHistory
    },
    historyItems(){
      let modulePath = this.$route.meta['OPTIONS'].state.name
      let historyFilter = this.$route.meta['RESPONSE_DATA_FILTER'].history.name
      return this.$store.state[modulePath][historyFilter]
    }

  },
  mounted(){
    this.$store.dispatch({
      type: this.loadHistory,
      id:this.id,
    })
    .then((response)=>{
      console.log(response)
    })
    .catch((error)=>{
      console.error(error)
    })
  }
}
</script>
